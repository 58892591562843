<template>
    <div v-if="form">
        <div class="my_personal" ref="personalTab">
            <a-form-model
                ref="profileForm"
                :model="form"
                :rules="rules">
                <a-row :gutter="30">
                    <a-col :md="recBlock ? 15 : 18" :xl="17">
                        <a-row :gutter="15">
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="last_name" :label="$t('last_name')" prop="last_name">
                                    <a-input v-model="form.last_name" size="large" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="first_name" :label="$t('first_name')" prop="first_name">
                                    <a-input v-model="form.first_name" size="large" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="middle_name" :label="$t('middle_name')" prop="middle_name">
                                    <a-input v-model="form.middle_name" size="large" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="job_title" :label="$t('job_title')" prop="job_title">
                                    <a-input v-model="form.job_title" size="large" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="contact_phone" :label="$t('contact_phone')" prop="contact_phone">
                                    <a-input v-model="form.contact_phone" size="large" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :md="12" :xl="8">
                                <a-form-model-item ref="birthday" :label="$t('birthday')" prop="birthday">
                                    <a-date-picker 
                                        v-model="form.birthday" 
                                        :showToday="false"
                                        :getCalendarContainer="getCalendarContainer"
                                        :placeholder="$t('select_date')"
                                        dropdownClassName="birthday_select"
                                        format="DD.MM.YYYY"
                                        size="large" 
                                        style="width: 100%;"
                                        @openChange="dateOpenChange" />
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-model-item ref="is_make_events_in_task_automatically" prop="is_make_events_in_task_automatically">
                                    <a-checkbox :checked="form.is_make_events_in_task_automatically" class="event_auto_check" @change="changeEventAuto">
                                        {{$t('is_make_events_in_task_automatically')}}
                                    </a-checkbox>
                                </a-form-model-item>
                            </a-col>
                            <a-col :span="24"></a-col>
                            <a-col :span="24">
                                <a-button type="primary" :loading="loading" size="large" @click="formSubmit()">
                                    {{$t('save')}}
                                </a-button>
                            </a-col>
                        </a-row>
                        <div v-if="tgBotLink" class="flex mt-6">
                            <qr-code :size="100" :text="tgBotLink"></qr-code>
                            <span class="ml-2">{{$t('qr_code_info')}}
                                <a class="blue_colors" target="_blank" :href="tgBotLink">{{$t('by_link')}}</a></span>
                        </div>
                        <div class="flex mt-6">
                            <a-button
                                type="ui"
                                size="large"
                                :loading="unsubLoading"
                                @click="unsubscribe()">
                                {{$t('unsubscribe')}}
                            </a-button>
                        </div>
                    </a-col>
                    <a-col v-if="recBlock" :md="9" :xl="7">
                        <div class="recommendations_block">
                            <h4>{{$t('recommendations')}}</h4>
                            <div class="list">
                                <div v-if="!user.last_name" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('fill_last_name')}}
                                </div>
                                <div v-if="!user.first_name" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('fill_first_name')}}
                                </div>
                                <div v-if="!user.middle_name" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('fill_middle_name')}}
                                </div>
                                <div v-if="!user.job_title" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('fill_job_title')}}
                                </div>
                                <div v-if="!user.birthday" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('fill_birthday')}}
                                </div>
                                <div v-if="!user.avatar" class="item">
                                    <i class="fi fi-rr-cross-small"></i> {{$t('upload_avatar')}}
                                </div>
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-form-model>
        </div>
    </div>
</template>

<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import { mapState } from 'vuex'
import IMask from 'imask'
export default {
    components: {
        QrCode: VueQRCodeComponent
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        recBlock() {
            if(this.user) {
                return !this.user.last_name || !this.user.first_name || !this.user.middle_name || !this.user.avatar || !this.user.job_title || !this.user.birthday
            }
            return false
        },
        tgBotURL() {
            return this.$store?.state?.config?.config?.tg_bot_settings?.url
        },
        tgBotLink() {
            if (this.user) {
                return `${this.tgBotURL}?start=${this.user.telegram_connect_token}`
            }
            return null
        }
    },
    data() {
        return {
            loading: false,
            unsubLoading: false,
            form: null,
            mask: '00.00.0000',
            rules: {
                last_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ],
                first_name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 255, message: this.$t('required_sym', { sym: 255 }), trigger: 'change' },
                ]
            }
        }
    },
    created() {
        this.form = {
            ...this.user,
            job_title: this.user.job_title || '',
            contact_phone: this.user.contact_phone || '',
            birthday: this.user.birthday ? this.$moment(this.user.birthday) : null
        }
    },
    methods: {
        changeEventAuto() {
            this.form.is_make_events_in_task_automatically = !this.form.is_make_events_in_task_automatically
        },
        dateOpenChange(status) {
            this.$nextTick(() => {
                let mask = null
                if(status) {
                    this.$nextTick(() => {
                        const input = document.querySelector('.birthday_select .ant-calendar-input')
                        if(input) {
                            mask = IMask(input, {
                                mask: this.mask
                            })
                        }
                    })
                } else
                    mask = null
            })
        },
        getCalendarContainer() {
            return this.$refs['personalTab']
        },
        async unsubscribe() {
            try {
                this.unsubLoading = true
                const {data} = await this.$http.post('notifications/tg_unsubscribe/', {})
                if(data) {
                    this.$message.info(this.$t('tg_unsubscribe_success'))
                }
            } catch(error) {
                console.log(error)
                this.$message.error(this.$t('error_occurred'))
            } finally {
                this.unsubLoading = false
            }
        },
        async formSubmit() {
            this.$refs.profileForm.validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const userForm = {...this.form}
                        if(userForm.birthday) {
                            userForm.birthday = this.$moment(userForm.birthday).format('YYYY-MM-DD')
                        }
                        const { data } = await this.$http.put('users/update_profile/', userForm)
                        if(data) {
                            localStorage.setItem('user', JSON.stringify(data))
                            this.$store.commit('user/SET_USER', data)
                            this.$message.success(this.$t('data_updated'))
                        }
                    } catch(error) {
                        console.log(error)
                        this.$message.error(this.$t('error_occurred'))
                    } finally {
                        this.loading = false
                    }
                } else {
                    this.$message.warning(this.$t('check_data'))
                }
            })
        }
    }
}
</script>


<style lang="scss" scoped>
.event_auto_check{
    display: flex;
    align-items: center;
    &::v-deep{
        .ant-checkbox + span{
            line-height: 22px;
        }
    }
}
.my_personal{
    .item{
        display: flex;
        &:not(:last-child){
            margin-bottom: 15px;
        }
        .label{
            min-width: 200px;
            font-weight: 300;
        }
    }
}
.recommendations_block{
    background-color: #eff2f5;
    border-radius: var(--borderRadius);
    padding: 15px;
    h4{
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
    }
    .list{
        .item{
            display: flex;
            align-items: center;
            i{
                font-size: 18px;
                margin-right: 3px;
                color: var(--red);
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
</style>